<template>
    <div>
        <div class="mt-4">
            <label class="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" for="LoggingEmailAddress">Email</label>
            <input class="input-primary" id="LoggingEmailAddress" v-model="username" type="email" />
        </div>

        <div class="mt-4">
            <div class="flex justify-between">
                <label class="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" for="loggingPassword">Senha</label>
                <a href="#" class="text-xs text-gray-500 dark:text-gray-300 hover:underline">Esqueceu a senha?</a>
            </div>

            <input class="input-primary" id="loggingPassword" v-model="password" type="password" />
        </div>

        <div class="mt-8">
            <button
                @click="handleLogin"
                :disabled="username.length == 0 || password.length == 0 || authenticating"
                class="w-full flex justify-center px-4 py-2 text-white disabled:opacity-50  bg-blue-500 rounded-md hover:bg-blue-600 "
            >
                <svg v-if="authenticating" class="w-4 h-4 mr-2 animate-spin self-center" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                    />
                </svg>

                Entrar
            </button>
        </div>
        <!-- <p class="text-center text-gray-500 text-sm mt-4">
      Ainda não tem uma conta?
      <router-link class="text-blue-500" to="signup">
        Cadatrastre-se
      </router-link>
    </p> -->
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Toast } from '@/services/toast.service.js';

export default {
    name: 'Login',
    components: {},
    data() {
        return {
            username: '',
            password: '',
            showErrModal: false,
            toastData: {
                msg: 'Incorrect login details',
                styleClass: 'bg-red-400 text-white',
                duration: 2000
            },
            toastVisible: false
        };
    },
    computed: {
        ...mapGetters('auth', ['loggedIn', 'authenticating', 'authenticationError', 'authenticationErrorCode'])
    },
    created() {},
    methods: {
        ...mapActions('auth', ['login', 'logout']),
        openToast(msg, style, duration) {
            let t = this.toastData;
            t.msg = msg;
            t.style = style;
            t.duration = duration;
            this.toastVisible = true;
        },
        handleLogin() {
            // Perform a simple validation that email and password have been typed in
            if (this.username != '' && this.password != '') {
                this.login({ username: this.username, password: this.password }).catch(err => {
                    if (err.errorCode == 400) {
                        Toast.fire({
                            icon: 'error',
                            title: 'Dados incorretos'
                        });
                    } else {
                        this.openToast('Unabled to verify credentials', '', 2000);
                    }
                    this.toastVisible = true;
                });
                this.password = '';
            }
        }
    }
};
</script>

<style></style>
